import React from 'react'
import { graphql } from 'gatsby'
import { BLOCKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import Layout from '../components/Layout/Layout'
import SEO from '../components/seo'
import Button from '../components/Button/Button'
import Header from '../components/Header/Header'
import ImgStageAbout from '../images/svgs/stage_about.svg'

const AboutPage = ({ data }) => {
  const { titleAboutText, titleJobteaser, titleJobteaserSection, titlePage, titleStage, titleTeam, slug } = data.contentfulAbout
  const content_shortStageIntroText = data.contentfulAbout.childContentfulAboutShortStageIntroTextTextNode.shortStageIntroText
  const content_shortAboutText = data.contentfulAbout.childContentfulAboutShortAboutTextRichTextNode.json
  const content_shortJobteaser = data.contentfulAbout.childContentfulAboutShortJobteaserRichTextNode.json
  
  let options = {
    renderNode: {
      [BLOCKS.HEADING_2]: (node, children) => <h2 className='mb-8 mt-4'>{children}</h2>,
      [BLOCKS.HEADING_3]: (node, children) => <h3 className='mb-6 mt-3'>{children}</h3>,
      [BLOCKS.PARAGRAPH]: (node, children) => <p className='mb-6'>{children}</p>
    },
    renderText: text =>
      text.split("\n").flatMap((text, i) => [i > 0 && <br />, text])
  }
  
  return (
    <Layout>
      <SEO title='Über uns' />
      <Header titleBig={titleStage} stageIntroText={content_shortStageIntroText} color='cream' className="stage-about"><ImgStageAbout/></Header>
      <div className='flex w-full xs:mb-0 xs:mt-16 md:mb-8 lg:mt-24 xl:my-32 px-6 md:px-8 justify-center'>
        <div className='flex w-full max-w-6xl flex-wrap'>
          <div className='flex flex-col xs:order-2 md:order-1 pr-8 lg:pr-32 md:w-8/12 max-w-4xl '>
            <p className='leading-relaxed'>{documentToReactComponents(content_shortAboutText)}</p>
          </div>
          <div className='flex flex-col xs:order-1 md:order-2 md:w-4/12 xs:mb-8 md:mb-0 max-w-4xl '>
            <h2 className='leading-relaxed xl:w-11/12'>{titleAboutText}</h2>
          </div>
        </div>
      </div>
      <div className='flex w-full flex-wrap xs:my-16 lg:my-16 xl:my-4 px-6 justify-center '>
        <div className='flex w-full text-center xs:mb-8 md:mb-16'>
          <span className='category-title mx-auto text-center'>{titleTeam}</span>
        </div>
        <div className='flex w-full text-center hidden'>
          <h2 className='leading-relaxed mx-auto text-center'></h2>
        </div>
        <div className=''>
           <div className='flex w-full flex-wrap mb-12 max-w-6xl'>
              {data.core.edges.map((node, index) => {
                return (
                  <div key={index} className='flex flex-col w-full sm:w-1/2 md:w-1/3 mb-12 p-2 items-center'>
                    <div className='mb-5 h-187 w-187'>
                      <img src={node.node.picture.fluid.src} className='rounded-full' alt={node.node.picture.description} />
                    </div>
                    <div className='flex w-full flex-wrap justify-center items-start'>
                      <span className='font-element w-full text-center block mb-1 font-semibold'>{node.node.name}</span>
                      <span className='w-full md:w-11/12 text-center block'>{node.node.fieldOfExpertise}</span>
                    </div>
                  </div>
                )
              })}
            </div>
        </div>
        <div className='flex w-full xs:mb-0 xs:mt-16 md:mb-8 lg:mt-16 px-6 md:px-8 justify-center hidden'>
        <div className='flex w-full max-w-6xl flex-wrap'>
          <div className='flex flex-col w-full'>
            <p className='category-title'>{titleJobteaserSection}</p>
          </div>
          <div className='flex flex-col md:w-4/12 xs:mb-8 md:mb-0 max-w-4xl '>
            <h2 className='leading-relaxed'>{titleJobteaser}</h2>
          </div>
          <div className='flex flex-col pr-8 lg:pl-32 md:w-8/12 max-w-4xl '>
            <p className='leading-relaxed'>{documentToReactComponents(content_shortJobteaser)}</p>
            <Button to='/jobs' withArrow className='p-4 xs:mt-4 md:mt-4 btn-white-text'>zu unseren Jobs</Button>
          </div>
        </div>
      </div>
      </div>
    </Layout>
  )
}

export default AboutPage

export const AboutQuery = graphql`
  query {
    contentfulAbout(slug: {eq: "about"}) {
      titleAboutText
      titleJobteaser
      titleJobteaserSection
      titlePage
      titleStage
      titleTeam
      slug
      childContentfulAboutShortStageIntroTextTextNode {
        shortStageIntroText
      }
      childContentfulAboutShortAboutTextRichTextNode {
        json
      }
      childContentfulAboutShortJobteaserRichTextNode {
        json
      }
    }
    ceos: allContentfulTeamMember(filter: {isCeo: {eq: true}}, sort: { fields: name, order: DESC }) {
      edges {
        node {
          name
          fieldOfExpertise
          picture {
            fluid(maxWidth: 187, maxHeight: 187, resizingBehavior: THUMB) {
              src
            }
          }
        }
      }
    }
    core: allContentfulTeamMember(filter: {isCeo: {eq: false}}, sort: {fields: employmentDate}) {
      edges {
        node {
          name
          fieldOfExpertise
          picture {
            fluid(maxWidth: 187, maxHeight: 187, resizingBehavior: THUMB) {
              src
            }
          }
        }
      }
    }
  }
`
